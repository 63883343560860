<template>
  <td
    class="main-table-td"
    :class="[
      { 'text-center': isTextCentered },
      { fixed: th.fixed },
      th.key,
      { header: isHeaderRow },
      { subheader: isSubheaderRow },
    ]"
    :style="{ 'text-align': th.textAlign }">
    <div
      v-for="(tableDataItem, key) in tableData"
      :key="key">
      <span v-if="th.key === 'selectColumn'">
        <MdtRadio
          v-if="maxSelected === 1"
          v-model="isSelected" />
        <MdtCheckbox
          v-else
          :id="row._id"
          v-model="isSelected"
          v-tooltip="checkbox.tooltip"
          :disabled="checkbox.disabled" />
      </span>
      <div
        v-else-if="Array.isArray(tableDataItem)"
        class="td-item">
        <span
          v-for="(item, i) in tableDataItem"
          :key="i"
          v-tooltip.top-start="item.tooltip"
          @click="onTdClick(row, th, item)">
          <!-- icon: orientation left -->
          <i
            v-if="item.icon && item.iconOrientation === 'left'"
            :class="[
              item.icon,
              { pointer: clickableIcons.includes(item.key) },
              { clickable: item.action },
              item.iconHighlight,
              { border: item.iconHighlightBorder },
              item.isActive || item.isActive === false
                ? item.isActive ? 'active-icon' : 'inactive-icon' : '',
            ]"
            :style="{
              color: item.iconColor,
              backgroundColor: item.iconHighlightColor,
              borderColor: item.iconHighlightBorderColor,
            }" />
          <!-- text -->
          <span
            v-if="item.value !== null && item.value !== undefined"
            :class="[
              {
                clickable: item.action,
                'line-through': item.textStrike,
              },
              item.textHighlight,
            ]"
            :style="{
              color: item.textColor,
              backgroundColor: item.textHighlightColor,
            }">
            {{ item.value }}
          </span>
          <!-- icon: orientation right -->
          <i
            v-if="item.icon && (!item.iconOrientation || item.iconOrientation === 'right')"
            :class="[
              item.icon,
              { pointer: clickableIcons.includes(item.key) },
              { clickable: item.action },
              item.iconHighlight,
              { border: item.iconHighlightBorder },
              item.isActive || item.isActive === false
                ? item.isActive ? 'active-icon' : 'inactive-icon' : '',
            ]"
            :style="{
              color: item.iconColor,
              backgroundColor: item.iconHighlightColor,
              borderColor: item.iconHighlightBorderColor,
            }" />
        </span>
      </div>
      <div
        v-else-if="tableDataItem
          && (isTableDataItemTypeText(tableDataItem.type)
            || isTableDataItemTypeHtml(tableDataItem.type))"
        v-tooltip="tableDataItem.tooltip"
        class="text"
        @click="onTdClick(row, th, tableDataItem)">
        <!-- icon: orientation left -->
        <i
          v-if="tableDataItem.icon && tableDataItem.iconOrientation === 'left'"
          :class="[
            tableDataItem.icon,
            { pointer: clickableIcons.includes(tableDataItem.key) },
            { clickable: tableDataItem.action },
            tableDataItem.iconHighlight,
            { border: tableDataItem.iconHighlightBorder },
            { left: tableDataItem.value !== '' },
            tableDataItem.isActive || tableDataItem.isActive === false
              ? tableDataItem.isActive ? 'active-icon' : 'inactive-icon' : '',
          ]"
          class="icon"
          :style="{
            color: tableDataItem.iconColor,
            backgroundColor: tableDataItem.iconHighlightColor,
            borderColor: tableDataItem.iconHighlightBorderColor,
          }" />
        <!-- text -->
        <div
          v-if="tableDataItem.value !== null && tableDataItem.value !== undefined"
          v-overflow-tooltip
          v-tooltip="{
            content: tableDataItem.value,
            trigger: isTableDataItemTypeHtml(tableDataItem.type) ? 'hover' : 'manual',
          }"
          class="text-div text-cut"
          :class="[
            {
              clickable: tableDataItem.action,
              'line-through': tableDataItem.textStrike,
            },
            tableDataItem.textHighlight,
          ]"
          :style="{
            color: tableDataItem.textColor,
            backgroundColor: tableDataItem.textHighlightColor,
          }">
          <span
            v-if="isTableDataItemTypeHtml(tableDataItem.type)"
            class="table-data-html"
            v-html="sanitizedContent(tableDataItem.value)" />
          <template v-else>
            {{ tableDataItem.value }}
          </template>
        </div>
        <!-- icon: orientation right -->
        <i
          v-if="tableDataItem.icon
            && (!tableDataItem.iconOrientation || tableDataItem.iconOrientation === 'right')"
          :class="[
            tableDataItem.icon,
            { pointer: clickableIcons.includes(tableDataItem.key) },
            { clickable: tableDataItem.action },
            tableDataItem.iconHighlight,
            { border: tableDataItem.iconHighlightBorder },
            { right: tableDataItem.value !== '' },
            tableDataItem.isActive || tableDataItem.isActive === false
              ? tableDataItem.isActive ? 'active-icon' : 'inactive-icon' : '',
          ]"
          class="icon"
          :style="{
            color: tableDataItem.iconColor,
            backgroundColor: tableDataItem.iconHighlightColor,
            borderColor: tableDataItem.iconHighlightBorderColor,
          }" />
      </div>
      <TableTdButtonCheck
        v-else-if="tableDataItem && tableDataItem.type === 'boolean'"
        ref="buttonCheck"
        :editable="editable"
        :item="tableDataItem"
        :value="tableDataItem.value"
        @input="tableDataItem.value = $event" />
      <div v-else-if="tableDataItem && tableDataItem.type === 'button'">
        <template
          v-if="(th.key === 'offerColumn' || th.key === 'waitlistProposeColumn')
            && th.choices.length">
          <TableTdButtonSelectB2B
            v-if="isProjectTypeSaleB2B"
            :key="`${th.key}-${row._id}`"
            :items="th.choices"
            :table-row="row"
            @tableTdButtonSelect="$emit('tableTdButtonSelect', $event)" />
          <TableTdButtonSelect
            v-else
            :key="`${th.key}-${row._id}`"
            :items="th.choices"
            :table-row="row"
            :tooltips="th.tooltips"
            @tableTdButtonSelect="$emit('tableTdButtonSelect', $event)" />
        </template>
        <MdtPdfPreview
          v-else-if="th.key === 'pdf_file'"
          :src="tableDataItem.value"
          disable-removal
          @modalClosed="$emit('modalClosed')" />
      </div>
      <MdtToggle
        v-else-if="tableDataItem && tableDataItem.type === 'toggleButton'"
        v-model="tableDataItem.value"
        @change="$emit('toggleButton', { row, th, value: tableDataItem.value })" />
      <div
        v-else-if="th.key === 'detailColumn' && detailColumn"
        class="detail-column-items">
        <div
          v-for="(button) in detailColumn.buttons"
          :key="`${button.key}-${row._id}`"
          v-tooltip="detailColumnButtonData(button).tooltip"
          class="btn btn-primary size-32 detail-btn"
          :class="{ 'btn-disabled': button.disabled }"
          @click="!button.disabled && onSelectDetailOption(button.key)">
          {{ detailColumnButtonData(button).displayName }}
        </div>
        <i
          v-for="(icon) in detailColumn.icons"
          :key="`${icon.key}-${row._id}`"
          v-tooltip="detailColumnButtonData(icon).tooltip"
          class="icon-circle clickable"
          :class="[`${detailColumnButtonData(icon).icon}`, { 'icon-disabled': icon.disabled }]"
          @click="!icon.disabled && onSelectDetailOption(icon.key)">
          <template
            v-if="icon.key === 'messenger' && handleMessengerBadge(row)">
            <i :class="handleMessengerBadge(row)" />
          </template>
        </i>
      </div>
    </div>
  </td>
</template>

<script>
import { clipboard } from '@/utility';
import TableTdButtonSelect from './TableTdButtonSelect.vue';
import TableTdButtonCheck from './TableTdButtonCheck.vue';
import TableTdButtonSelectB2B from './TableTdButtonSelectB2B.vue';

export default {
  name: 'TableTd',
  components: {
    TableTdButtonSelect,
    TableTdButtonCheck,
    TableTdButtonSelectB2B,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    th: {
      type: Object,
      required: true,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    maxSelected: {
      type: Number,
      default: undefined,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    textCenteredColumns: {
      type: Array,
      required: true,
    },
  },
  data() {
    const { translate } = this.$options.filters;
    return {
      translate,
      clickableIcons: ['favoriteApplication', 'editApplicationEvaluation'],
      msg: {
        advertisement: translate('admin_marketing_advertisement'),
        detailView: translate('general_details'),
        notAvailable: translate('admin_object_not_available'),
      },
    };
  },
  computed: {
    tableData() {
      return Array.isArray(this.row[this.th.key]) ? this.row[this.th.key] : [this.row[this.th.key]];
    },
    isSelected: {
      get() {
        const id = this.row._id;
        return !!this.selectedRows.find((selected) => selected._id === id);
      },
      set(checked) {
        const { row } = this;

        if (checked) {
          if (this.maxSelected === 1) {
            this.selectedRows.pop(); // eslint-disable-line vue/no-mutating-props
          }
          this.selectedRows.push(row); // eslint-disable-line vue/no-mutating-props
        } else {
          const len = this.selectedRows.length;
          for (let i = 0; i < len; i++) {
            const item = this.selectedRows[i];
            if (item._id === row._id) {
              this.selectedRows.splice(i, 1); // eslint-disable-line vue/no-mutating-props
              break;
            }
          }
        }
      },
    },
    checkbox() {
      let tooltip = '';
      let disabled = false;

      // if we use row readonly handle tooltip and disabled from readonly object
      // eslint-disable-next-line no-underscore-dangle
      if (this.row._readonly !== undefined) {
        // eslint-disable-next-line no-underscore-dangle
        tooltip = this.row._readonly.unavailable_tooltip;
        // eslint-disable-next-line no-underscore-dangle
        disabled = this.row._readonly.value === true;
      } else if (!this.isSelected && this.selectedRows.length >= this.maxSelected) {
        // otherwise if selected rows >= max selected
        // disable not selected rows and show not-available tooltip
        tooltip = this.msg.notAvailable;
        disabled = true;
      }

      return {
        tooltip,
        disabled,
      };
    },
    isTextCentered() {
      return !!this.th.icon || this.textCenteredColumns.includes(this.th.key);
    },
    isHeaderRow() {
      // eslint-disable-next-line no-underscore-dangle
      return this.row._rowType === 'header';
    },
    isSubheaderRow() {
      // eslint-disable-next-line no-underscore-dangle
      return this.row._rowType === 'subheader';
    },
    detailColumn() {
      if (!this.th.buttons || !this.th.buttons.length) return null;

      // define buttons and icons
      const buttons = [];
      const icons = [];

      // filter detail columns (buttons and icons) properly
      this.th.buttons.forEach((detailColumn) => {
        // eslint-disable-next-line no-underscore-dangle
        const disabledButton = this.row._detail_column?.disabled_buttons?.[detailColumn.key];
        // if disabledButton has tooltip update btn disabled and tooltip
        if (disabledButton?.tooltip) {
          detailColumn.disabled = true;
          detailColumn.disabledTooltip = disabledButton.tooltip;
        } else {
          detailColumn.disabled = false;
          detailColumn.disabledTooltip = '';
        }
        // if button is available
        if (!disabledButton || disabledButton?.tooltip) {
          // check if permitted
          let permitted = true;
          switch (detailColumn.key) {
            case 'advertisement':
              permitted = this.isPermitted('object_advertisement');
              break;
            case 'messenger':
              permitted = this.isPermitted('shared_communication');
              break;
            case 'waitlistSendCriteriaForm':
              permitted = this.isPermitted('waitlist');
              break;
            default: break;
          }
          // if permitted -> push button or icon regarding type properly
          if (permitted) {
            switch (detailColumn.type) {
              case 'button':
                buttons.push(detailColumn);
                break;
              case 'icon':
                icons.push(detailColumn);
                break;
              default: break;
            }
          }
        }
      });

      return JSON.parse(JSON.stringify({
        buttons,
        icons,
      }));
    },
    isProjectTypeSaleB2B() {
      return this.$store.getters.isProjectType('saleb2b');
    },
    isTableDataItemTypeText() {
      return (type) => type === 'text';
    },
    isTableDataItemTypeHtml() {
      return (type) => type === 'html';
    },
    handleMessengerBadge() {
      return (row) => {
        // eslint-disable-next-line no-underscore-dangle
        const messenger = row._messenger;
        if (messenger.has_unread_mail) {
          return 'fa-solid fa-circle has-bounce';
        }
        if (messenger.has_bounce && messenger.has_unread_bounce) {
          return 'fas fa-exclamation-circle has-bounce';
        }
        return false;
      };
    },
  },
  methods: {
    onTdClick(row, th, additional) {
      const prop = additional || this.tableData[0];
      if (!prop) return;

      switch (prop.action) {
        case 'sidepanel':
          this.openSidepanel(row, prop);
          break;
        case 'link':
          this.openExternalLink(prop);
          break;
        case 'favoriteApplication':
        case 'favoriteApply':
          this.$emit('setFavorite', row);
          break;
        case 'clipboard':
          this.copyToClipboard(prop);
          break;
        case 'reservation':
          this.$emit('reservation', prop);
          break;
        default: break;
      }
    },
    openSidepanel(row, prop) {
      // Emit correct sidepanel key with data to open it
      const sidepanelKey = {
        apartmentOfferDetail: 'ApartmentOffer',
        applicationOfferDetail: 'ApplicationOffer',
        editAdult: 'ApplicationAdult',
        editApartment: 'ApartmentEdit',
        editApplication: 'ApplicationEdit',
        editApplicationEvaluation: 'ApplicationEvaluation',
        editChild: 'ApplicationChild',
        offerDetailDeclined: 'ApplicationOfferDeclined',
        editCostItem: 'BudgetCostPlanEdit',
        editCostCategory: 'BudgetCostCategoryEdit',
        showMessenger: 'Messenger',
        viewTotalPoints: 'ApplicationTotalPoints',
        editBuilding: 'BuildingEdit',
        editInvestor: 'InvestorEdit',
        editB2BInterested: 'InterestedPersonB2BEdit',
        investorOffer: 'InvestorOffer',
      };

      const key = sidepanelKey[prop.key];
      if (key) {
        this.$emit('openSidepanel', {
          key,
          row,
          additional: prop,
        });
      }
    },
    openExternalLink(prop) {
      const { url } = prop;
      if (url) window.open(url, '_blank');
    },
    copyToClipboard(prop) {
      const { url } = prop;
      if (url) {
        clipboard.copyText(url);
        this.$notify.success(this.translate('admin_copied_to_clipboard'));
      }
    },
    saveTableTd() {
      const buttonCheckRef = this.$refs.buttonCheck;
      if (buttonCheckRef && buttonCheckRef[0].saveValue) buttonCheckRef[0].saveValue();
    },
    onSelectDetailOption(key) {
      const option = {
        key,
        item: this.row,
      };
      this.$emit('selectDetailOption', option);
      if (key === 'messenger') {
        this.$emit('openSidepanel', {
          key: 'Messenger',
          additional: this.row._messenger, // eslint-disable-line no-underscore-dangle
        });
      }
      if (key === 'dmsDetailView') {
        // eslint-disable-next-line no-underscore-dangle
        const dmsUuid = option.item._dms_detail_view_url.dms_uuid;
        if (!dmsUuid) return;

        if (dmsUuid === 'create_dms') {
          const payload = {
            application: option.item.id.value,
          };
          this.$store.state.apartments.applicationsTable.loading = true;
          this.$store.dispatch('dms/createDms', payload)
            .then((data) => {
              window.open(`${window.location.origin}/dms/${data.dms_uuid}`, '_blank'); // eslint-disable-line no-underscore-dangle
            }).catch(this.showResponseError)
            .finally(() => {
              this.$store.state.apartments.applicationsTable.loading = false;
            });
        } else {
          window.open(`${window.location.origin}/dms/${dmsUuid}`, '_blank'); // eslint-disable-line no-underscore-dangle
        }
      }
    },
    detailColumnButtonData(button) {
      // there are two types of detail buttons: 'icons' and 'buttons'
      // icons have: 'tooltip' and 'icon'
      // buttons have: 'displayName' and 'tooltip'
      switch (button.key) {
        case 'edit':
          return {
            tooltip: button.disabledTooltip || button.tooltip || this.translate('admin_edit'),
            icon: 'fas fa-pen',
          };
        case 'detailView':
          return {
            tooltip: button.disabledTooltip || button.tooltip || this.translate('general_details'),
            icon: 'fas fa-angle-right',
          };
        case 'advertisement':
          return {
            tooltip: button.disabledTooltip || button.tooltip || this.translate('admin_marketing_advertisement'),
            icon: 'fas fa-bullhorn',
          };
        case 'messenger':
          return {
            tooltip: button.disabledTooltip || button.tooltip || this.translate('admin_messenger'),
            icon: 'fas fa-envelope',
          };
        case 'sendEmail':
          return {
            tooltip: button.disabledTooltip || button.tooltip || this.translate('admin_send_email'),
            icon: 'fas fa-envelope',
          };
        case 'download':
          return {
            tooltip: button.disabledTooltip || button.tooltip || this.translate('general_download'),
            icon: 'fas fa-download',
          };
        case 'delete':
          return {
            tooltip: button.disabledTooltip || button.tooltip || this.translate('general_delete'),
            icon: 'fas fa-trash',
          };
        case 'dmsDetailView':
          return {
            icon: 'fas fa-folder-open',
            tooltip: button.disabledTooltip || button.tooltip || this.translate('general_dms'),
          };
        case 'buyingConfirmationForm':
          return {
            displayName: button.displayName || this.translate('general_buying_confirmation_button'),
            tooltip: button.disabledTooltip || button.tooltip,
          };
        case 'createDigitalReservation':
          return {
            displayName: button.displayName || this.translate('general_digital_reservation'),
            tooltip: button.disabledTooltip || button.tooltip,
          };
        case 'sendPaymentInfoEmail':
          return {
            displayName: button.displayName || this.translate('general_reservation_payment'),
            tooltip: button.disabledTooltip || button.tooltip,
          };
        case 'sendAccountingReminderEmail':
          return {
            displayName: button.displayName || this.translate('general_accounting_reminder'),
            tooltip: button.disabledTooltip || button.tooltip,
          };
        case 'waitlistSendCriteriaForm':
          return {
            displayName: button.displayName || this.translate('admin_waitlist_email'),
            tooltip: button.disabledTooltip || button.tooltip,
          };
        case 'comment':
          return {
            icon: 'fas fa-comment-alt',
            tooltip: button.disabledTooltip
              || button.tooltip
              || this.translate('general_comment'),
          };
        case 'present':
          return {
            displayName: button.displayName || this.translate('general_present'),
            tooltip: button.disabledTooltip || button.tooltip,
          };
        default: return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-table-td {
  position: relative;

  &.header {
    background-color: $table-header-color !important;
    color: $color-text-primary;
  }

  &.subheader {
    background-color: $table-subheader-color !important;
    color: $color-text-primary;
  }

  &.detailColumn {
    .detail-btn {
      height: 32px;
      margin-right: 5px;
    }

    .icon-circle {
      width: 32px;
      line-height: 32px;
      padding: 0;
      background-color: $color-theme;
      color: $color-white;
    }
  }

  .td-item {
    padding-bottom: 5px;

    &:last-child {
      padding-bottom: 0;
    }

    & > span {
      margin-right: 5px;
    }
  }

  .table-data-html ::v-deep *{
    text-overflow: ellipsis!important;
    overflow: hidden!important;
  }

  .text {
    .text-div {
      display: inline-block;
      max-width: 25vw;
    }
  }

  .text-circle,
  .text-square,
  .icon-circle {
    display: inline-block;
    min-width: 20px;
    line-height: 20px;
    margin-right: 5px;
    font-size: 14px;
    text-align: center;

    &:not(.clickable) {
      cursor: default;
    }
  }

  .text-circle,
  .icon-circle {
    position: relative;
    border-radius: 50%;
    .has-bounce {
      position: absolute;
      z-index:1;
      top:-8px;
      right:-8px;
      font-size: 16px;
      border-radius: 50%;
      border: 2px solid $color-white;
      color: $color-danger;
      background: $color-white;
    }
  }

  .icon-circle.border {
    border: 1px solid;
  }

  .text-square {
    padding: 0 8px;
    border-radius: 4px;
    font-weight: 700;
    height: 24px;
    display: inline-flex;
    align-items: center;
  }

  .icon-detail-arrow {
    padding-left: 2px !important;
    font-size: 1.25rem;
  }

  .text {
    .icon {
      &.left {
        position: relative;
        top: -3px;
        right: 16px;
      }

      &.right {
        position: relative;
        top: -3px;
        left: 11px;
      }
    }

    .text-circle + .fa-flag {
      position: absolute;
      top: calc(50% - 8px);
      right: -32px;
      color: $color-text-secondary !important;
    }
  }

  .line-through {
    text-decoration: line-through;
  }

  .detail-column-items {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
