import axios from 'axios';
import token from '@/api/interceptors/token';
import helpers from '@/utility/helpers';

const config = process.env.NODE_ENV === 'production' ? '/config.json' : '/config.local.json';
const apiServiceAsync = async () => {
  try {
    const { data } = await axios.get(config);
    let { baseApiUrl } = data;

    // Update base api url with project type domain
    baseApiUrl = helpers.handleDoubleTopLevelDomain(baseApiUrl);

    const apiService = axios.create({
      baseURL: baseApiUrl,
      timeout: data.axiosTimeout || 120000,
    });

    apiService.interceptors.request.use(token.request, token.requestError);
    apiService.interceptors.response.use(token.response, token.responseError);

    return apiService;
  } catch (error) {
    console.error('Error fetching config:', error);
    throw error;
  }
};

export default apiServiceAsync;
