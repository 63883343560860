<template>
  <div class="bulk-options">
    <ModalBulkMail
      v-if="modalKey === 'bulk-mail'"
      :selected-rows="selectedRowsIds"
      :bulk-option="selectedBulkOption"
      @close="closeModal" />
    <ModalDeactivateApplication
      v-if="modalKey === 'deactivate-application'"
      :selected-rows="selectedRowsIds"
      :reasons="deactivateModal.reasons"
      @close="closeModal" />
    <ModalActivateApplication
      v-if="modalKey === 'activate-application'"
      :selected-rows="selectedRowsIds"
      @close="closeModal" />
    <MdtModalConfirmation
      v-if="modalKey === 'delete'"
      :title="deleteBulkExtraOptionView.title || ''"
      :info-msg="deleteBulkExtraOptionView.description || ''"
      type="danger"
      @close="modalKey = ''"
      @confirm="onDelete" />
    <ModalBudgetCreateFee
      v-if="modalKey === 'createFee'"
      :selected-rows="selectedRowsIds"
      @close="closeModal" />
    <div class="bulk-options-default">
      <div
        v-for="(item, i) in bulkOptions"
        :key="i"
        v-tooltip="bulkOptionsHandler(item.view.name).tooltip"
        class="btn btn-basic btn-size-32 bulk-item"
        :class="{ 'btn-primary': i === 0, 'btn-disabled': !isBulkOptionsActive(item) }"
        @click="bulkOptionSelect(item)">
        {{ item.text }}
      </div>
    </div>
    <div
      class="bulk-options-extra"
      :class="{ active: isBulkExtraOptionsActive }">
      <template v-if="legend && legend.length">
        <div class="bulk-legend">
          <div
            v-for="(item, i) in legend"
            :key="i"
            class="bulk-item-legend">
            <i
              :class="[item.icon, 'icon']"
              :style="{ color: item.color }" />
            {{ item.displayName }}
          </div>
        </div>
        <span
          v-if="handledBulkExtraOptions.length
            || (randomPeopleViewing && randomPeopleViewing.enabled)
            || (excelDownload && Object.keys(excelDownload).length)
            || (settings && settings.enabled && isPermitted('shared_view_settings'))"
          class="bulk-item-legend-separator" />
      </template>
      <MdtDropdownIcon
        v-for="(item, i) in handledBulkExtraOptions"
        :key="i"
        :icon="item.icon"
        :items="item.items"
        :right-aligned="true"
        :disabled="!isBulkExtraOptionsActive"
        :tooltip="item.tooltip"
        :tooltip-position="'bottom'"
        :single-click-mode="true"
        class="bulk-item-extra flex-center-v"
        @delete="extraOptionsActionClick('delete', item)"
        @downloadDossier="extraOptionsActionClick('downloadDossier')"
        @downloadFlyer="extraOptionsActionClick('downloadFlyer')"
        @downloadBusinessCard="extraOptionsActionClick('downloadBusinessCard')"
        @remExport="extraOptionsActionClick('remExport')"
        @immo1Export="extraOptionsActionClick('immo1Export')"
        @immo2Export="extraOptionsActionClick('immo2Export')"
        @abaimmoExport="extraOptionsActionClick('abaimmoExport')"
        @csvExport="extraOptionsActionClick('csvExport')"
        @downloadHonorary="extraOptionsActionClick('downloadHonorary')" />
      <div
        v-if="randomApplicantsViewing && randomApplicantsViewing.enabled"
        class="bulk-item-extra icon-random-applications-viewing pointer">
        <i
          v-tooltip.bottom="randomApplicantsViewing.tooltip"
          class="fas fa-random"
          @click="$emit('openRandomApplicantsViewing')" />
      </div>
      <div
        v-if="randomPeopleViewing && randomPeopleViewing.enabled"
        class="bulk-item-extra icon-random-people-viewing pointer"
        :class="{ 'bulk-item-extra-disabled': randomPeopleViewing.clickDisabled }">
        <i
          v-tooltip.bottom="randomPeopleViewing.tooltip"
          class="fas fa-random"
          @click="!randomPeopleViewing.clickDisabled ? $emit('openRandomPeopleViewing') : null" />
      </div>
      <div
        v-if="excelDownload && Object.keys(excelDownload).length"
        class="bulk-item-extra icon-excel-download pointer">
        <i
          v-tooltip.bottom="excelDownload.tooltip"
          class="fas fa-file-excel"
          @click="excelDownloadClick" />
      </div>
      <div
        v-if="settings && settings.enabled && isPermitted('shared_view_settings')"
        class="bulk-item-extra icon-settings pointer">
        <i
          v-tooltip.bottom="settings.tooltip"
          :class="settings.icon"
          @click="$emit('openReorder')" />
      </div>
      <MdtDropdownIcon
        v-if="createNew.enabled"
        :icon="'fas fa-plus'"
        :items="createNewItems"
        :right-aligned="true"
        :show-as-button="true"
        :single-click-mode="true"
        :tooltip-position="'bottom'"
        class="create-new"
        @createNew="onCreateNew" />
    </div>
  </div>
</template>

<script>
import { pdf, helpers } from '@/utility';
import ModalBudgetCreateFee from '@/components/budget/ModalBudgetCreateFee.vue';
import ModalDeactivateApplication from './ModalDeactivateApplication.vue';
import ModalActivateApplication from './ModalActivateApplication.vue';
import ModalBulkMail from './ModalBulkMail.vue';

export default {
  name: 'BulkOptions',
  components: {
    ModalDeactivateApplication,
    ModalActivateApplication,
    ModalBulkMail,
    ModalBudgetCreateFee,
  },
  props: {
    bulkOptions: {
      type: Array,
      default: () => [],
    },
    bulkExtraOptions: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    createNew: {
      type: Object,
      default: () => ({}),
    },
    excelDownload: {
      type: Object,
      default: () => ({}),
    },
    randomPeopleViewing: {
      type: Object,
      default: () => ({}),
    },
    randomApplicantsViewing: {
      type: Object,
      default: () => ({}),
    },
    legend: {
      type: Array,
      default: () => [],
    },
    tableId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalKey: '',
      deactivateModal: {
        reasons: [],
      },
      selectedBulkOption: null,
      loading: false,
    };
  },
  computed: {
    isBulkOptionsActive() {
      return (item) => item.enabled
        || (!!this.selectedRows.length && this.bulkOptionsHandler(item.view.name).available);
    },
    isBulkExtraOptionsActive() {
      return !!this.selectedRows.length;
    },
    selectedRowsIds() {
      return this.selectedRows.map((row) => row._id) || [];
    },
    bulkOptionsHandler() {
      return (itemName) => {
        const bulk = {
          available: true,
          tooltip: [],
        };
        const bulkItem = this.bulkOptions
          .find((item) => item.view && item.view.name === itemName);

        if (bulkItem && bulkItem.view.validation === 'language') {
          // All languages have to be the same for bulk to be available
          bulk.available = this.selectedRows.every((row) => (
            // eslint-disable-next-line no-underscore-dangle
            row._language === this.selectedRows[0]._language
          ));
          if (!bulk.available) {
            bulk.tooltip = this.$options.filters.translate('admin_selected_items_have_different_lang_message');
          }
          return bulk;
        }

        // If bulk button is available handle availability and tooltip
        if (bulkItem) {
          this.selectedRows
            .forEach((row) => {
              const bulkAvailable = row[this.bulkOptionsMapper(itemName)];
              if (bulkAvailable && !bulkAvailable.value) {
                bulk.available = false;
                if (!bulk.tooltip.includes(bulkAvailable.unavailable_tooltip)) {
                  bulk.tooltip.push(bulkAvailable.unavailable_tooltip);
                }
              }
            });
          if (bulk.tooltip.length) bulk.tooltip = bulk.tooltip.join(', ');
          else bulk.tooltip = null;
          return bulk;
        }

        // If bulk button is not available return defaults
        bulk.tooltip = null;
        return bulk;
      };
    },
    deleteBulkExtraOptionView() {
      const option = this.bulkExtraOptions.find((item) => item?.view?.name === 'delete');
      return option ? option.view : {};
    },
    createNewItems() {
      if (!this.createNew?.enabled) return [];
      return this.createNew.choices.map((item) => ({
        action: 'createNew',
        key: item.key,
        name: item.displayName,
      }));
    },
    handledBulkExtraOptions() {
      // check bulk extra options availability regarding _disabled_bulk_extra_options
      // add reactive properties disabled and tooltip if the items are not available
      const handledBulkExtraOptions = JSON.parse(JSON.stringify(this.bulkExtraOptions));
      handledBulkExtraOptions.forEach((bulkExtraOption) => {
        bulkExtraOption.items.forEach((item) => {
          // find at least one selected row which is disabled for downloading current loop item
          const disabledRow = this.selectedRows
            .find(
              // eslint-disable-next-line no-underscore-dangle
              (selectedRow) => selectedRow?._disabled_bulk_extra_options?.[item.action]?.value,
            );

          // set disabled tooltip for current loop item
          const disabledTooltip = disabledRow
            // eslint-disable-next-line no-underscore-dangle
            ? disabledRow._disabled_bulk_extra_options[item.action]?.tooltip : '';

          // if bulk extra item is not allowed for any of the selected rows
          // set item disabled and tooltip properties
          if (disabledRow) {
            if (!('disabled' in item)) this.$set(item, 'disabled', true);
            else item.disabled = true;
            if (!('tooltip' in item)) this.$set(item, 'tooltip', disabledTooltip);
            else item.tooltip = disabledTooltip;
          } else { // clear disabled and tooltip properties
            if (!('disabled' in item)) this.$set(item, 'disabled', false);
            else item.disabled = false;
            if (!('tooltip' in item)) this.$set(item, 'tooltip', '');
            else item.tooltip = '';
          }
        });
      });
      return handledBulkExtraOptions;
    },
    getAdditionalTableApi() {
      return this.$store.getters['table/additionalTableApiPoint'](this.tableId);
    },
    getAdditionalTableFilters() {
      return this.$store.getters['table/additionalTableAdditionalFilters'](this.tableId);
    },
    getAdditionalTableBasicFilters() {
      return this.$store.getters['table/additionalTableBasicFilters'](this.tableId);
    },
    getAdditionalTableSortKey() {
      return this.$store.getters['table/additionalTableSortKey'](this.tableId);
    },
  },
  methods: {
    bulkOptionsMapper(name) {
      // map bulk option item name and row bulk available object
      return {
        showMarketingWizard: '_bulk_available',
        declineApplication: '_bulk_decline_available',
        setObjectToSold: '_set_to_sold_available',
        deactivateInterested: '_bulk_deactivate_interested',
        deactivateConfirmation: '_bulk_deactivate_confirmation',
        allocationProposal: '_allocation_proposal_available',
      }[name];
    },
    bulkOptionSelect(item) {
      if (!this.isBulkOptionsActive(item)) return;

      this.selectedBulkOption = item;
      switch (item.view.name) {
        case 'sendEmail':
          this.modalKey = 'bulk-mail';
          break;
        case 'deactivateApplication':
          this.deactivateModal.reasons = item.view.choices;
          this.modalKey = 'deactivate-application';
          break;
        case 'activateApplication':
          this.modalKey = 'activate-application';
          break;
        case 'showCalculateHonorary':
          this.modalKey = 'createFee';
          break;
        default: break;
      }

      this.$emit('bulkOptionSelect', item);
    },
    extraOptionsActionClick(action, item) {
      if (this.loading) return;
      this.loading = true;

      switch (action) {
        case 'downloadDossier':
          this.$store.dispatch('applications/downloadMultipleDossiers', this.selectedRowsIds).then((data) => {
            const contentType = data.headers['content-type'];
            const fileName = data.headers['content-disposition'].split('filename="')[1].slice(0, -1);
            pdf.downloadFile(data.data, fileName, contentType);
          }).catch(this.showResponseError)
            .finally(() => {
              this.loading = false;
            });
          break;
        case 'downloadFlyer':
          this.$store.dispatch('apartments/downloadMultipleFlyers', this.selectedRowsIds).then((data) => {
            const contentType = data.headers['content-type'];
            const fileName = data.headers['content-disposition'].split('filename="')[1].slice(0, -1);
            pdf.downloadFile(data.data, fileName, contentType);
          }).catch(this.showResponseError)
            .finally(() => {
              this.loading = false;
            });
          break;
        case 'downloadBusinessCard':
          this.$store.dispatch('apartments/downloadMultipleBusinessCards', this.selectedRowsIds).then((data) => {
            const contentType = data.headers['content-type'];
            const fileName = data.headers['content-disposition'].split('filename="')[1].slice(0, -1);
            pdf.downloadFile(data.data, fileName, contentType);
          }).catch(this.showResponseError)
            .finally(() => {
              this.loading = false;
            });
          break;
        case 'remExport':
        case 'immo1Export':
        case 'immo2Export':
        case 'csvExport': {
          const params = {
            tenantIds: this.selectedRowsIds,
            exportAction: action,
          };
          this.$store.dispatch('tenants/downloadMultipleExports', params).then((data) => {
            const contentType = data.headers['content-type'];
            const fileName = data.headers['content-disposition'].split('filename="')[1].slice(0, -1);
            pdf.downloadFile(data.data, fileName, contentType);
          }).catch(this.showResponseError)
            .finally(() => {
              this.loading = false;
            });
          break;
        }
        case 'abaimmoExport':
          this.$store.dispatch('apartments/downloadCsvAbaImmo', this.selectedRowsIds.toString()).then((data) => {
            const contentType = data.headers['content-type'];
            const fileName = data.headers['content-disposition'].split('filename="')[1].slice(0, -1);
            pdf.downloadFile(data.data, fileName, contentType);
          }).catch(this.showResponseError)
            .finally(() => {
              this.loading = false;
            });
          break;
        case 'downloadHonorary': {
          this.$store.dispatch('budget/downloadFeeExport').then((response) => {
            const contentType = response.headers['content-type'];
            const fileName = response.headers['content-disposition'].split('filename="')[1].slice(0, -1);
            pdf.downloadFile(response.data, fileName, contentType);
          }).catch(this.showResponseError)
            .finally(() => {
              this.loading = false;
            });
          break;
        }
        case 'delete': {
          if (item.view?.options?.customDialog) {
            this.$emit('bulkExtraOptionSelect', item);
          } else this.modalKey = 'delete';
          this.loading = false;
          break;
        }
        default:
          this.loading = false;
          break;
      }
    },
    onDelete() {
      if (this.loading) return;
      this.loading = true;

      const params = {
        targets: this.selectedRowsIds,
        bulkAction: 'delete',
      };
      this.$store.dispatch('table/deleteSelectedRows', params).then((data) => {
        this.$store.dispatch('notify', data?.notify);
      }).catch(this.showResponseError)
        .finally(() => {
          this.modalKey = '';
          this.loading = false;
        });
    },
    excelDownloadClick() {
      if (this.loading) return;

      const {
        api,
        payload,
        additionalTableSortKey,
      } = this.additionalTableExcelDownload();

      const params = {
        additionalTableApi: api,
        payload: {},
      };
      if (this.selectedRows.length) {
        params.payload.targets = this.selectedRowsIds;
      } else {
        const {
          'table/activeTableStatusFilter': tableStatus,
          'table/searchVal': searchVal,
          'table/sortKey': sortKey,
          'table/basicFilters': basicFilters,
        } = this.$store.getters;
        Object.assign(params.payload, {
          search: helpers.valueOrUndefined(searchVal),
          sort: helpers.valueOrUndefined(sortKey)
            || additionalTableSortKey,
          ...basicFilters,
          ...payload,
        });
        // don't bind mainStatusFilters to additionalTablesFilters
        // when downloading excel for additional table
        if (!this.checkIsAdditionalTable()) {
          params.payload[tableStatus.key] = tableStatus.choice.value;
        }
      }
      this.loading = true;
      this.$store.dispatch('table/downloadExcel', params).then((response) => {
        const contentType = response.headers['content-type'];
        const fileName = response.headers['content-disposition'].split('filename="')[1].slice(0, -1);
        pdf.downloadFile(response.data, fileName, contentType);
      }).catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
        });
    },
    checkIsAdditionalTable() {
      switch (this.tableId) {
        case 'investorsWithOffer':
        case 'interestedInvestors':
        case 'matchingInvestors':
        case 'interested':
        case 'applications':
          return true;
        default:
          return false;
      }
    },
    additionalTableExcelDownload() {
      const settings = {};
      if (this.checkIsAdditionalTable()) {
        settings.api = this.getAdditionalTableApi;
        settings.payload = {
          ...this.getAdditionalTableFilters,
          ...this.getAdditionalTableBasicFilters,
        };
        settings.additionalTableSortKey = this.getAdditionalTableSortKey;
      }
      return settings;
    },
    closeModal() {
      // Reset modal data
      this.deactivateModal.reasons = [];
      this.modalKey = '';
    },
    onCreateNew(data) {
      const key = typeof data === 'object' ? data.key : data;
      this.$emit('createNew', key);
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-options {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  line-height: 32px;
}

.bulk-options-default,
.bulk-options-extra {
  display: flex;
}

.bulk-options-default {
  flex-wrap: wrap;
}

.bulk-options-extra {
  align-items: center;
  flex-wrap: nowrap;
}

.bulk-options-default {
  .bulk-item {
    margin-right: 5px;
  }
}

.bulk-options-extra {
  .bulk-legend {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    .bulk-item-legend {
      padding-right: 16px;
      color: $color-text-primary;
      font-size: 14px;
      white-space: nowrap;

      .icon {
        padding-right: 8px;
      }
    }
  }

  .bulk-item-legend-separator {
    height: 22px;
    margin: 0 10px 0 4px;
    border-right: 1px solid $border-color;
  }

  .bulk-item-extra {
    position: relative;
    padding: 0 10px;
    color: $color-text-secondary;
    align-self: baseline;

    &:hover {
      color: $color-text-primary;
    }

    &.bulk-item-extra-disabled {
      opacity: 0.3;
    }

    i {
      font-size: 20px;
    }
  }

  .bulk-item-extra-actions {
    position: absolute;
    top: calc(100% - 1px);
    right: 0;
    background-color: $color-back-primary;
    z-index: 3;
    border-radius: 4px;
    box-shadow: 0px 2px 20px 0px #0000002a;
    overflow: hidden;

    .action {
      padding: 0 20px;
      line-height: 50px;
      border-bottom: 1px solid $border-color;

      &:hover {
        background-color: rgba($color-back-hover, 0.5);
      }

      &.action-disabled {
        cursor: not-allowed;

        &:hover {
          background-color: inherit;
        }
      }
    }
  }

  .create-new {
    color: initial;
    padding-left: 10px;
  }
}

.icon-settings,
.icon-excel-download,
.icon-random-people-viewing,
.icon-random-applications-viewing {
  color: $color-text-primary;
}
</style>
