let uniqueIdCount = 0;

export default {
  isUndefined(value) {
    return !value && value !== 0 && value !== false;
  },
  isNotUndefined(value) {
    return !!value || value === 0 || value === false;
  },
  valueOrUndefined(value) {
    return this.isNotUndefined(value) ? value : undefined;
  },
  isEmptyString(value) {
    return value !== undefined && value.toString().trim() === '';
  },
  parseIntoString(value) {
    return typeof value !== 'string' ? JSON.stringify(value) : value;
  },
  debounce(method, delay) {
    clearTimeout(method.timerId);
    method.timerId = setTimeout(() => {
      method();
    }, delay);
  },
  fileIconByType(fileExtension) {
    switch (fileExtension.split('.').pop().toLowerCase()) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'svg': return 'fa-file-image';
      case 'pdf': return 'fa-file-pdf';
      case 'csv': return 'fa-file-csv';
      case 'xlsx': return 'fa-file-excel';
      case 'zip': return 'fa-file-archive';
      default: return 'fa-file';
    }
  },
  handleScrollbarsOnModalOpen() {
    // get all existing mdt-modal-components in the DOM
    const mdtModalComponents = document.querySelectorAll('.mdt-modal-component');
    // if number of opened mdt-modal-components = 1
    // then hide browser's scrollbar
    if (mdtModalComponents.length === 1) {
      document.body.classList.add('no-scroll');
    }
  },
  handleScrollbarsOnModalClose() {
    // get all existing mdt-modal-components in the DOM
    const mdtModalComponents = document.querySelectorAll('.mdt-modal-component');
    // if number of opened mdt-modal-components = 0
    // then revert back browser's scrollbar
    if (mdtModalComponents.length === 0) {
      document.body.classList.remove('no-scroll');
    }
  },
  setFontPublic(font, baseBackendUrl) {
    if (font && baseBackendUrl) {
      const link = document.createElement('link');
      link.setAttribute('type', 'text/css');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', `${baseBackendUrl}/static/template-assets/styles/${font}`);
      document.head.appendChild(link);
    }
  },
  uniqueId(prefix = '') {
    uniqueIdCount += 1;
    return `${prefix}${uniqueIdCount}`;
  },
  generateUniqueId(length = 8) {
    return Math.random().toString(36).substring(2, 2 + length);
  },
  changeFavicon(faviconPath) {
    if (faviconPath) {
      const favicon = document.getElementById('favicon');
      favicon.href = faviconPath;
    }
  },
  getSubdomainName() {
    return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'rent';
  },
  handleDoubleTopLevelDomain(baseApiUrl) {
    // Update base api url with project type domain
    if (baseApiUrl && baseApiUrl.includes('[project_type_domain]')) {
      const domain = window.location.hostname.split('.').slice(-1)[0];
      baseApiUrl = baseApiUrl.replace('[project_type_domain]', domain);
    } else if (baseApiUrl.includes('[double_top_level_domain]')) {
      const doubleDomain = window.location.hostname.split('.').slice(-2).join('.');
      baseApiUrl = baseApiUrl.replace('[double_top_level_domain]', doubleDomain);
    }
    return baseApiUrl;
  },
  charStringToNumber(val, character) {
    return (val && parseInt(val.toString().replaceAll(character, ''), 10)) || null;
  },
  numberSeparator(val, charLength, separator) {
    const pattern = `\\B(?=(\\d{${charLength}})+(?!\\d))`;
    const regEx = new RegExp(pattern, 'g');
    if (!val) return '';
    return val.toString().replace(/\D/g, '').replace(regEx, separator);
  },
  getFileExtension(fileUrl) {
    if (!fileUrl) return '';
    return fileUrl.split('.').pop();
  },
  checkProjectTypeFromUrl(projectType) {
    const { host } = window.location;
    return host.includes(projectType);
  },
  // Loop over object of objcts and return only object that has prop
  getFilteredValues(objects, prop) {
    return Object.values(objects).filter((obj) => obj?.[prop]);
  },
  isEvery(value, array = []) {
    return array.every((item) => item === value);
  },
  // Remove duplicates from array of strings
  // e.g. ['string_1', 'string_2', 'string_3','string_3', 'string_3', 'string_1', 'string_2'];
  removeStringDuplicates(arr) {
    return [...new Set(arr)];
  },
};
